var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    { attrs: { text: "Department: " + _vm.dropdownLabel } },
    [
      _c(
        "b-dropdown-item",
        {
          on: {
            click: function($event) {
              return _vm.setDepartment(null)
            }
          }
        },
        [_vm._v("\n    All Departments\n  ")]
      ),
      _vm._v(" "),
      _vm._l(_vm.departmentsInTray, function(dept) {
        return _c(
          "b-dropdown-item",
          {
            key: dept,
            on: {
              click: function($event) {
                return _vm.setDepartment(dept)
              }
            }
          },
          [_vm._v("\n    " + _vm._s(dept) + "\n  ")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }