var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "advanced-search" } },
    [
      _c("div", [
        _vm.$store.state.search.searchComplete
          ? _c("span", { attrs: { id: "advanced-search-filter" } }, [
              _c(
                "span",
                {
                  staticClass: "advanced-search-element ml-4",
                  attrs: { id: "advanced-search-filter-button", tabindex: "0" },
                  on: { click: _vm.toggleSearchFilters }
                },
                [_vm._v("\n        Filter\n      ")]
              ),
              _vm._v(" "),
              _c("img", {
                staticClass: "icon-remove ml-2",
                attrs: { src: "/images/icons/x_black.png" },
                on: { click: _vm.resetAdvancedSearchFilters }
              })
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.$store.state.search.searchComplete
        ? _c(
            "b-popover",
            {
              attrs: {
                show: _vm.useFilters,
                container: "advanced-search",
                target: "advanced-search-filter-button",
                triggers: "click blur",
                placement: "bottom"
              },
              on: {
                "update:show": function($event) {
                  _vm.useFilters = $event
                }
              }
            },
            [_c("advanced-search-filters")],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }