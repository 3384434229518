var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "text-uppercase font-weight-bold" }, [
      _vm._v("\n    Instructor Networks\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb-3", attrs: { id: "vis-description" } },
      [
        _c("p", [
          _vm._v(
            "\n      Type in an instructor’s name to see the faculty with whom they've\n      co-taught or with whom they share departmental affiliations and/or\n      intellectual affinities. Click on the name of one of these colleagues\n      (left column) to highlight their specific sub-network of connections;\n      click on one of the department names (right column) to reveal their individual course offerings.\n    "
          )
        ]),
        _vm._v(" "),
        _c("semester-input"),
        _vm._v(" "),
        _c("course-level-input", {
          model: {
            value: _vm.courseLevel,
            callback: function($$v) {
              _vm.courseLevel = $$v
            },
            expression: "courseLevel"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb-4 px-0 col-sm-4", attrs: { id: "searchContainer" } },
      [
        _c(
          "div",
          { attrs: { id: "search" } },
          [
            _c(
              "b-form",
              { on: { submit: _vm.onSubmit } },
              [
                _c(
                  "b-input-group",
                  [
                    _c("b-input-group-prepend", { attrs: { "is-text": "" } }, [
                      _c("img", {
                        staticClass: "icon",
                        attrs: { src: "/images/icons/eye_black.png" }
                      })
                    ]),
                    _vm._v(" "),
                    _c("b-form-input", {
                      staticClass: "search pl-0",
                      attrs: {
                        id: "instructorName",
                        type: "search",
                        required: "",
                        autocomplete: "none",
                        placeholder: "Enter instructor name"
                      },
                      model: {
                        value: _vm.instructorName,
                        callback: function($$v) {
                          _vm.instructorName = $$v
                        },
                        expression: "instructorName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _c("h4", { staticClass: "text-center text-uppercase font-weight-bold" }, [
      _vm._v("\n    " + _vm._s(_vm.titleName) + "\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNoResultsContainer,
            expression: "showNoResultsContainer"
          }
        ],
        staticClass: "mt-5 text-center",
        attrs: { id: "noResultsContainer" }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm._l(_vm.predefinedInstructors, function(instructor) {
          return _c("p", { key: instructor }, [
            _c(
              "span",
              {
                staticClass: "instructor",
                on: {
                  click: function($event) {
                    return _vm.forceInstructorSearch(instructor)
                  }
                }
              },
              [_vm._v("\n        " + _vm._s(instructor) + "\n      ")]
            )
          ])
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showNoResultsContainer,
            expression: "!showNoResultsContainer"
          }
        ],
        attrs: { id: "interfaceContainer" }
      },
      [_c("div", { attrs: { id: "visContainer" } })]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n      This instructor has no network for the selected semester and course level.\n      "
      ),
      _c("br"),
      _vm._v(
        "\n      Try changing the semester or course level, or start with a different instructor.\n    "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }