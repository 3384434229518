var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "facet" }, [
    _c("h5", [_vm._v(_vm._s(_vm.facet.title))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "facet-list mb-2" },
      _vm._l(_vm.items, function(item) {
        return _c("div", { key: item.id, staticClass: "facet-item clearfix" }, [
          _c("div", { staticClass: "float-left label-container" }, [
            _c(
              "label",
              { staticClass: "mb-1 text-white text-uppercase pointer" },
              [
                _c("input", {
                  attrs: { type: "checkbox" },
                  domProps: { value: item.id, checked: item.selected },
                  on: { change: _vm.toggleCheckbox }
                }),
                _vm._v("\n          " + _vm._s(item.value) + "\n        ")
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "count float-right text-white" }, [
            _vm._v("\n        " + _vm._s(item.count) + "\n      ")
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }