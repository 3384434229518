var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pt-5", attrs: { id: "home-content" } }, [
      _c("p", [_vm._v("\n    Welcome to\n  ")]),
      _vm._v(" "),
      _c("p", [
        _c("img", {
          attrs: {
            id: "logo",
            src: "/images/logos/curricle_white_bg_no_beta.svg"
          }
        })
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    A transformative tool for course discovery, selection, and planning.\n  "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mb-4" }, [
        _c("iframe", {
          attrs: {
            src: "https://player.vimeo.com/video/354848830",
            width: "640",
            height: "360",
            frameborder: "0",
            allow: "autoplay; fullscreen",
            allowfullscreen: ""
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row vertical-align" }, [
        _c("div", { staticClass: "col-sm-12 col-md-4" }, [
          _c(
            "span",
            {
              staticClass: "text-center w-100",
              attrs: { id: "login-link-container" }
            },
            [
              _c(
                "a",
                {
                  staticClass: "text-decoration-none",
                  attrs: { href: "/users/sign_in" }
                },
                [_vm._v("\n          Sign in with HarvardKey\n        ")]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12 col-md-8" }, [
          _vm._v("\n      Check out the latest\n      "),
          _c(
            "a",
            {
              attrs: {
                href: "https://curricle.wpengine.com/",
                target: "_blank"
              }
            },
            [_vm._v("\n        news")]
          ),
          _vm._v("\n      on updates and new features.\n    ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }