var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "text-uppercase font-weight-bold" }, [
      _vm._v("\n    Course Universe\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb-4", attrs: { id: "vis-description" } },
      [
        _c("p", [
          _vm._v(
            "\n      The word “university” comes from the Latin word “universitas,” which\n      means totality, and Course Universe allows you to explore that totality\n      at the level of the curriculum as a whole. Zoom out and survey the\n      university’s entire course catalog. Zoom in and examine individual\n      departments. Zoom in further to explore specific courses. The courses\n      and departments loaded into your current schedule are highlighted.\n    "
          )
        ]),
        _vm._v(" "),
        _c("semester-input"),
        _vm._v(" "),
        _c("course-level-input", {
          model: {
            value: _vm.courseLevel,
            callback: function($$v) {
              _vm.courseLevel = $$v
            },
            expression: "courseLevel"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb-4 px-0 col-sm-4", attrs: { id: "searchContainer" } },
      [
        _c(
          "div",
          { attrs: { id: "search" } },
          [
            _c(
              "b-form",
              { on: { submit: _vm.performSearch } },
              [
                _c(
                  "b-input-group",
                  [
                    _c("b-input-group-prepend", { attrs: { "is-text": "" } }, [
                      _c("img", {
                        staticClass: "icon",
                        attrs: { src: "/images/icons/eye_black.png" }
                      })
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.searchQuery,
                          expression: "searchQuery"
                        }
                      ],
                      staticClass: "search pl-0 form-control",
                      attrs: { type: "search", placeholder: "Search courses" },
                      domProps: { value: _vm.searchQuery },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.searchQuery = $event.target.value
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("b-input-group-append", { attrs: { "is-text": "" } }, [
                      _c("img", {
                        staticClass: "icon pointer",
                        attrs: { src: "/images/icons/return_arrow.png" },
                        on: { click: _vm.performSearch }
                      })
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { attrs: { id: "visWrapper" } }, [
      _c("canvas", { attrs: { id: "visCANVAS" } }),
      _vm._v(" "),
      _c("svg", { attrs: { id: "visSVG" } }, [_c("g")])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { display: "none" }, attrs: { id: "noResults" } },
      [
        _c("strong", [
          _vm._v("\n      No results found for that search.\n    ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }