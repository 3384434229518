var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mt-4" }, [
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-4 pt-1" },
      [
        _c(
          "b-button",
          {
            staticClass: "w-100",
            attrs: { variant: "dark", target: "_blank", href: _vm.link }
          },
          [_vm._v("\n      " + _vm._s(_vm.buttonLabel) + "\n    ")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-12 col-md-8" }, [
      _vm._v("\n    " + _vm._s(_vm.description) + "\n  ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }