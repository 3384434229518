var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row py-5" }, [
    _c(
      "div",
      { staticClass: "col-md-8" },
      [
        _c("h3", [_vm._v("\n      Tools:\n    ")]),
        _vm._v(" "),
        _c("p", { staticClass: "mb-5" }, [
          _vm._v(
            "\n      Curricle is the nexus of a network of tools and platforms that provide you with support along the course selection journey. Here are a few to note.\n    "
          )
        ]),
        _vm._v(" "),
        _vm._l(_vm.rows, function(row, index) {
          return _c(
            "tools-row",
            _vm._b({ key: index }, "tools-row", row, false)
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }