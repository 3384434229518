var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "span",
      {
        staticClass: "pointer",
        attrs: { id: "search-field-dropdown", tabindex: "0" }
      },
      [
        _vm._v("\n    Apply to: "),
        _c("strong", [_vm._v(_vm._s(_vm.dropdownTitle))]),
        _vm._v(" "),
        _c("font-awesome-icon", { attrs: { icon: "caret-down" } }),
        _vm._v(" "),
        _c(
          "b-popover",
          {
            attrs: {
              target: "search-field-dropdown",
              triggers: "click blur",
              placement: "bottom"
            }
          },
          [
            _c("b-form-checkbox-group", {
              attrs: {
                options: _vm.options,
                stacked: "",
                name: "search-fields"
              },
              model: {
                value: _vm.selected,
                callback: function($$v) {
                  _vm.selected = $$v
                },
                expression: "selected"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }