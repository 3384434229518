var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "calendar-item text-uppercase font-weight-bold p-2",
      class: {
        conflicted: _vm.conflicted,
        provisional: _vm.provisional,
        selected: _vm.selected,
        hidden: _vm.hidden
      },
      style: _vm.computedStyle,
      on: {
        click: function($event) {
          return _vm.selectCourse(_vm.item)
        }
      }
    },
    [
      _vm._v("\n  " + _vm._s(_vm.item.title) + "\n\n  "),
      !_vm.readOnly
        ? _c("course-action", {
            attrs: {
              "course-id": _vm.item.id,
              invert: _vm.selected,
              type: _vm.courseActionType
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "course-component" }, [
        _vm._v("\n    " + _vm._s(_vm.item.component) + "\n  ")
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }