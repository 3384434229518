var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      attrs: { id: _vm.id, size: _vm.size, variant: _vm.variant },
      on: { click: _vm.clickHandler }
    },
    [
      _vm._v("\n  Share Schedule\n\n  "),
      _c(
        "b-popover",
        {
          attrs: { target: _vm.id, placement: "bottom", triggers: "click blur" }
        },
        [
          _vm._v(
            "\n    Share this link to allow others to view your schedule:\n    "
          ),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "shared-schedule-url" }, [
            _vm._v("\n      " + _vm._s(_vm.scheduleUrl) + "\n    ")
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          ref: "tooltip",
          attrs: { target: _vm.id, placement: "bottom", triggers: "hover" }
        },
        [
          _vm._v("\n    Generates a unique"),
          _c("br"),
          _vm._v("\n    URL of your schedule"),
          _c("br"),
          _vm._v("\n    that you can share with"),
          _c("br"),
          _vm._v("\n    advisers and friends\n  ")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }