var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.updatedAt
    ? _c("div", [
        _vm._v("\n  Data last updated on " + _vm._s(_vm.updatedAt) + ". See "),
        _c("a", { attrs: { href: "https://my.harvard.edu/" } }, [
          _vm._v("my.harvard")
        ]),
        _vm._v(" for the most recent changes to courses.\n")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }