var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.inactiveKeywords.length > 0,
          expression: "inactiveKeywords.length > 0"
        }
      ],
      staticClass: "mt-3"
    },
    [
      _c(
        "b-alert",
        { attrs: { show: "", variant: "secondary" } },
        [
          _c("span", { staticClass: "inactive" }, [
            _vm._v("\n      Inactive\n    ")
          ]),
          _vm._v(" "),
          _vm._l(_vm.inactiveKeywords, function(keyword, idx) {
            return _c("keyword", {
              key: idx,
              attrs: { "keyword-idx": idx, keyword: keyword }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }