var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mb-5" }, [
    _c("div", { staticClass: "col-sm-12 col-md-5" }, [
      _c("img", { staticClass: "img-fluid rounded", attrs: { src: _vm.image } })
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-4" },
      [
        _c("p", [
          _c("strong", { staticClass: "text-uppercase" }, [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.text))]),
        _vm._v(" "),
        _c("b-button", { attrs: { to: _vm.link, variant: "dark" } }, [
          _vm._v("\n      View\n    ")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }