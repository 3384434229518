var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-5" }, [
    _vm.resultsTotalCount === 0
      ? _c("div", [
          _c("strong", [
            _vm._v("\n      No results found for that search.\n    ")
          ])
        ])
      : _c("div", { staticClass: "clearfix" }, [
          _c(
            "div",
            { staticClass: "float-left", staticStyle: { width: "28%" } },
            [_c("strong", [_vm._v(_vm._s(_vm.resultsTotalCount) + " results")])]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "float-left" },
            [
              _c("search-results-sort", {
                attrs: { "search-type": _vm.searchType }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "float-right text-right" },
            [
              _c(
                "b-form-checkbox",
                {
                  model: {
                    value: _vm.showConflicts,
                    callback: function($$v) {
                      _vm.showConflicts = $$v
                    },
                    expression: "showConflicts"
                  }
                },
                [
                  _vm._v(
                    "\n        show results with conflicts (" +
                      _vm._s(_vm.conflictCount) +
                      ")\n      "
                  )
                ]
              )
            ],
            1
          )
        ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "results mt-4" },
      [
        _vm._l(_vm.results, function(result) {
          return _c("search-result-row", {
            key: result.id,
            attrs: {
              course: result,
              selected: _vm.currentCourse && _vm.currentCourse.id === result.id,
              conflicts: _vm.courseConflicts[result.id]
                ? _vm.courseConflicts[result.id].conflicts
                : [],
              "is-conflicted": _vm.courseConflicts[result.id]
                ? _vm.courseConflicts[result.id].hasConflict
                : false,
              "show-conflicts": _vm.showConflicts
            }
          })
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.resultsMoreAvailable,
                expression: "resultsMoreAvailable"
              }
            ],
            staticClass: "text-center my-4"
          },
          [
            _c(
              "b-button",
              {
                staticClass: "pointer",
                attrs: { variant: "secondary" },
                on: { click: _vm.searchAgain }
              },
              [_vm._v("\n        More Results\n      ")]
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }