var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "the-alert" } },
    [
      _c(
        "b-alert",
        {
          attrs: { show: _vm.dismissCountDown, fade: "" },
          on: {
            dismissed: function($event) {
              _vm.dismissCountDown = 0
            },
            "dismiss-count-down": _vm.countDownChanged
          }
        },
        [_vm._v("\n    " + _vm._s(_vm.alertText) + "\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }