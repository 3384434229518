var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.course.id
    ? _c(
        "div",
        { staticClass: "p-3 col-md-10", attrs: { id: "selected-course" } },
        [
          _c("div", [
            _c(
              "span",
              { staticClass: "float-right" },
              [
                _c("font-awesome-icon", {
                  staticClass: "pointer",
                  attrs: { icon: "times" },
                  on: { click: _vm.CLEAR_SELECTED_COURSE }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mb-3 row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _vm.userAuthenticated && !_vm.courseIdImported(_vm.course.id)
                  ? [
                      _c("course-action", {
                        staticClass: "course-action",
                        attrs: { "course-id": _vm.course.id, type: "tray" }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "font-weight-bold text-uppercase" },
                        [
                          _vm.courseIdInTray(_vm.course.id)
                            ? _c("span", [
                                _vm._v(
                                  "\n            Remove from schedule\n          "
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n            Add to schedule\n          "
                                )
                              ])
                        ]
                      )
                    ]
                  : [_vm._v("\n         \n      ")]
              ],
              2
            )
          ]),
          _vm._v(" "),
          _vm.course.id
            ? _c(
                "selected-course-details",
                _vm._b(
                  { attrs: { course: _vm.course } },
                  "selected-course-details",
                  _vm.$props,
                  false
                )
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }