var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "background-black pointer text-uppercase",
      attrs: { id: "navbar-control" },
      on: { click: _vm.trayClose }
    },
    [_vm._v("\n  Menu\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }