var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-uppercase", attrs: { id: "feedback-container" } },
    [
      _c(
        "a",
        {
          attrs: {
            target: "_blank",
            href: "https://forms.gle/pJGzsArGDAGaqN2R8"
          }
        },
        [
          _vm._v("\n    Send feedback\n\n    "),
          _c("font-awesome-icon", {
            staticClass: "ml-2",
            attrs: { icon: ["far", "comment-alt"] }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }