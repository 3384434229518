var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mb-3 row" }, [
      _c("div", { staticClass: "col-lg-7" }, [
        _c("p", { staticClass: "font-weight-bold text-uppercase" }, [
          _vm._v("\n        " + _vm._s(_vm.course.title) + "\n      ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-3 text-uppercase" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.course.subject + " " + _vm.course.catalogNumber)
        ),
        _c("br"),
        _vm._v("\n      " + _vm._s(_vm.course.component || "—")),
        _c("br"),
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.course.academicGroup +
                " — " +
                _vm.course.termName +
                " " +
                _vm.course.termYear
            ) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-lg-2 text-uppercase" }, [
        _vm.qGuideUrl
          ? _c("span", [
              _c(
                "a",
                {
                  attrs: { target: "_blank", href: _vm.qGuideUrl },
                  on: {
                    click: function($event) {
                      return _vm.$matomo.trackEvent(
                        "Q Guide",
                        "Click",
                        _vm.course.id
                      )
                    }
                  }
                },
                [_vm._v("\n          Q Guide\n        ")]
              ),
              _vm._v(" "),
              _c("br")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { target: "_blank", href: _vm.syllabusUrl },
            on: {
              click: function($event) {
                return _vm.$matomo.trackEvent(
                  "Syllabus",
                  "Click",
                  _vm.course.id
                )
              }
            }
          },
          [_vm._v("\n        Syllabus\n      ")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-lg-7 mb-4" },
        [
          _c("p", { staticClass: "heading" }, [
            _vm._v("\n        Description\n      ")
          ]),
          _vm._v(" "),
          _vm.course.courseDescriptionLong
            ? _c("truncate", {
                attrs: {
                  length: 500,
                  text: _vm.course.courseDescriptionLong,
                  clamp: "[...]",
                  less: "[Close]",
                  type: "html"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "heading mt-4" }, [
            _vm._v("\n        Course Attributes\n      ")
          ]),
          _vm._v(" "),
          _vm._l(_vm.course.courseAttributes, function(attribute) {
            return _c("span", { key: attribute.id }, [
              _c("strong", [
                _vm._v(_vm._s(attribute.crseAttributeDescription) + ":")
              ]),
              _vm._v(
                " " + _vm._s(attribute.crseAttrValueDescription) + "\n        "
              ),
              _c("br")
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-lg-5" },
        [
          _c("p", { staticClass: "heading" }, [
            _vm._v("\n        Instructors\n      ")
          ]),
          _vm._v(" "),
          _vm._l(_vm.course.courseInstructors, function(instructor) {
            return _c("div", { key: instructor.id }, [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n          " + _vm._s(instructor.displayName) + "\n        "
                )
              ])
            ])
          }),
          _vm._v(" "),
          _vm.userAuthenticated
            ? _c("annotations", { attrs: { course: _vm.course } })
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }