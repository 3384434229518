var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      key: _vm.keyword.ident,
      staticClass: "text-uppercase mr-2",
      class: _vm.keywordClass,
      attrs: { id: _vm.kwId }
    },
    [
      _c("span", { on: { click: _vm.bodyClick } }, [
        _vm._v("\n    " + _vm._s(_vm.keyword.text) + "\n  ")
      ]),
      _vm._v("  \n  "),
      _c("font-awesome-icon", {
        attrs: { icon: "times" },
        on: { click: _vm.closeClick }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }