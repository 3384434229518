var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row curricle-search-result mb-5 mx-1 py-2",
      class: {
        selected: _vm.selected,
        conflicted: _vm.isConflicted,
        hiddenConflict: _vm.hiddenConflict
      },
      style: _vm.borderStyle
    },
    [
      _c(
        "div",
        { staticClass: "col-md-1 pt-1" },
        [
          _c("course-action", {
            staticClass: "mr-1",
            attrs: {
              "course-id": _vm.course.id,
              invert: _vm.selected,
              type: "tray"
            }
          }),
          _vm._v(" "),
          _c("course-action", {
            staticClass: "mr-2",
            attrs: {
              "course-id": _vm.course.id,
              invert: _vm.selected,
              type: "schedule"
            }
          }),
          _vm._v(" "),
          _c("span", { staticClass: "text-muted" }, [_vm._v("\n      —\n    ")])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-md-9 pointer pl-0",
          on: {
            click: function($event) {
              return _vm.selectCourse(_vm.course)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "course-title font-weight-bold text-uppercase",
              class: _vm.courseIdStyles(_vm.course.id)
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.course.subject) +
                  " " +
                  _vm._s(_vm.course.catalogNumber) +
                  ": " +
                  _vm._s(_vm.course.title) +
                  "\n    "
              )
            ]
          ),
          _vm._v(" "),
          _c("hr", { staticClass: "m-0", class: _vm.selected }),
          _vm._v(" "),
          _c("span", { staticClass: "course-instructor" }, [
            _vm._v("\n      " + _vm._s(_vm.instructorNames) + "\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "course-component text-uppercase" }, [
            _vm._v("\n      " + _vm._s(_vm.course.component)),
            _c("br"),
            _vm._v(
              "\n      " +
                _vm._s(_vm.course.termName) +
                " " +
                _vm._s(_vm.course.termYear) +
                "\n    "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-2 px-0" },
        [
          _c("class-meeting-time", {
            attrs: {
              condensed: true,
              conflicts: _vm.conflicts,
              course: _vm.course
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }