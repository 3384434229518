var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "tray" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.trayVisible,
            expression: "trayVisible"
          }
        ],
        staticClass: "pt-3 px-3"
      },
      [
        _c("div", { staticClass: "your-tray-parent clearfix" }, [
          _c("p", [
            _vm._v("\n        Schedule\n\n        "),
            _c(
              "span",
              { staticClass: "float-right" },
              [
                _c("font-awesome-icon", {
                  staticClass: "pointer ml-2",
                  attrs: { icon: "times" },
                  on: { click: _vm.trayClose }
                })
              ],
              1
            )
          ])
        ]),
        _vm._v(" "),
        _c("tray-list"),
        _vm._v(" "),
        _c(
          "b-button",
          {
            staticClass: "w-100 mt-4",
            attrs: { variant: "light", to: "/plan/week" }
          },
          [_vm._v("\n      Go to Week View\n    ")]
        ),
        _vm._v(" "),
        _c("share-schedule-link", {
          staticClass: "mt-2 w-100",
          attrs: { id: "share-schedule-link-tray", variant: "light" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }