var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      directives: [
        {
          name: "b-modal",
          rawName: "v-b-modal.export-modal",
          modifiers: { "export-modal": true }
        }
      ],
      attrs: { id: "myHarvardLink", size: "sm", variant: "dark" },
      on: {
        click: function($event) {
          return _vm.$matomo.trackEvent("Export", "Click")
        }
      }
    },
    [
      _vm._v("\n  Export to my.harvard\n\n  "),
      _c(
        "b-modal",
        { attrs: { id: "export-modal", size: "lg" } },
        [
          _c("template", { slot: "default" }, [
            _c("p", { staticClass: "px-3" }, [
              _vm._v(
                "\n        Before you leave Curricle to continue enrolling in my.harvard...\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "px-3" }, [
              _vm._v("\n        We ask you to take "),
              _c("strong", [_vm._v("three minutes")]),
              _vm._v(" of your time and\n        "),
              _c("strong", [_vm._v("fill out a short survey")]),
              _vm._v(
                " on your experience with using\n        Curricle. Your feedback is invaluable to our continued efforts to\n        improve the tool for the semesters to come.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "px-3" }, [
              _vm._v("\n        Thanks!\n      ")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row px-3 mb-3" }, [
              _c(
                "div",
                { staticClass: "col pr-1" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-center",
                      attrs: {
                        id: "button-no",
                        href: _vm.myHarvardLink,
                        target: "_blank",
                        variant: "light"
                      }
                    },
                    [
                      _vm._v(
                        "\n            NO, take me to my.harvard\n          "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col pl-1" },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        id: "button-yes",
                        href: "https://forms.gle/pJGzsArGDAGaqN2R8",
                        target: "_blank",
                        variant: "dark"
                      }
                    },
                    [
                      _vm._v("\n            YES, I'd like to\n            "),
                      _c("br"),
                      _vm._v("\n            share my feedback!\n          ")
                    ]
                  )
                ],
                1
              )
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: "myHarvardLink",
            placement: "bottom",
            triggers: "hover"
          }
        },
        [
          _vm._v("\n    Sends all courses in"),
          _c("br"),
          _vm._v("\n    your schedule that are"),
          _c("br"),
          _vm._v("\n    offered this semester"),
          _c("br"),
          _vm._v("\n    to my.harvard\n  ")
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }