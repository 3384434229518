var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._v("\n  Semester:\n  "),
    _c(
      "span",
      {
        staticClass: "pointer",
        attrs: { id: "search-semester-range", tabindex: "0" }
      },
      [
        _vm._v("\n     "),
        _c("strong", [_vm._v(_vm._s(_vm.rangeLabel))]),
        _vm._v(" "),
        _c("font-awesome-icon", { attrs: { icon: "caret-down" } }),
        _vm._v(" "),
        _c(
          "b-popover",
          {
            attrs: {
              target: "search-semester-range",
              triggers: "click blur",
              placement: "bottom"
            }
          },
          [
            _vm.allowRange
              ? _c("span", [_vm._v("Select one or more semesters:")])
              : _c("span", [_vm._v("Select a semester:")]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c(
              "b-form",
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "justify-content-md-left" },
                      [
                        _c("b-form-radio-group", {
                          attrs: {
                            options: _vm.optionsTermName,
                            stacked: "",
                            name: "search-start-term"
                          },
                          model: {
                            value: _vm.searchTermStart,
                            callback: function($$v) {
                              _vm.searchTermStart = $$v
                            },
                            expression: "searchTermStart"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-col",
                      { staticClass: "justify-content-md-left" },
                      [
                        _c("b-form-select", {
                          staticClass: "year-select",
                          attrs: { options: _vm.optionsTermYear },
                          model: {
                            value: _vm.searchYearStart,
                            callback: function($$v) {
                              _vm.searchYearStart = $$v
                            },
                            expression: "searchYearStart"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.allowRange
                      ? [
                          _c(
                            "b-col",
                            { staticClass: "justify-content-md-left" },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  model: {
                                    value: _vm.searchTermUseRange,
                                    callback: function($$v) {
                                      _vm.searchTermUseRange = $$v
                                    },
                                    expression: "searchTermUseRange"
                                  }
                                },
                                [_vm._v("\n                to\n              ")]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.searchTermUseRange,
                                  expression: "searchTermUseRange"
                                }
                              ],
                              staticClass: "justify-content-md-left"
                            },
                            [
                              _c("b-form-radio-group", {
                                attrs: {
                                  options: _vm.optionsTermName,
                                  name: "search-end-term",
                                  stacked: ""
                                },
                                model: {
                                  value: _vm.searchTermEnd,
                                  callback: function($$v) {
                                    _vm.searchTermEnd = $$v
                                  },
                                  expression: "searchTermEnd"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.searchTermUseRange,
                                  expression: "searchTermUseRange"
                                }
                              ],
                              staticClass: "justify-content-md-left"
                            },
                            [
                              _c("b-form-select", {
                                staticClass: "year-select",
                                attrs: { options: _vm.optionsTermYearEnd },
                                model: {
                                  value: _vm.searchYearEnd,
                                  callback: function($$v) {
                                    _vm.searchYearEnd = $$v
                                  },
                                  expression: "searchYearEnd"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }