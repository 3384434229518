var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "course-action" },
    [
      _c("font-awesome-icon", {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.d999.hover",
            value: true,
            expression: "true",
            modifiers: { d999: true, hover: true }
          }
        ],
        staticClass: "pointer",
        class: { "text-white": _vm.invert },
        attrs: { icon: _vm.icon, title: _vm.tooltip },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.click($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }