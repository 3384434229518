var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pt-5", attrs: { id: "home-content" } }, [
    _c("p", [_vm._v("\n    Welcome to\n  ")]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "mb-5" },
      [
        _c(
          "router-link",
          {
            staticClass: "text-decoration-none text-uppercase",
            attrs: { to: "/explore" }
          },
          [_c("strong", [_vm._v("explore")])]
        ),
        _vm._v("\n    the curriculum and\n    "),
        _c(
          "router-link",
          {
            staticClass: "text-decoration-none text-uppercase",
            attrs: { to: "/search" }
          },
          [_c("strong", [_vm._v("search")])]
        ),
        _vm._v("\n    for courses\n\n    "),
        _c("br"),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "text-decoration-none text-uppercase",
            attrs: { to: "/plan" }
          },
          [_c("strong", [_vm._v("plan")])]
        ),
        _vm._v("\n    out your semester\n\n    "),
        _c("br"),
        _vm._v(" "),
        _c("strong", { staticClass: "text-uppercase" }, [
          _vm._v("\n      ENROLL\n    ")
        ]),
        _vm._v("\n    in classes at\n    "),
        _c(
          "a",
          {
            staticClass: "text-decoration-none font-italic",
            attrs: { href: "https://portal.my.harvard.edu" }
          },
          [_vm._v("\n      my.harvard")]
        ),
        _vm._v("\n    by exporting your schedule\n  ")
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("img", {
        attrs: {
          id: "logo",
          src: "/images/logos/curricle_white_bg_no_beta.svg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n    Check out the latest\n    "),
      _c(
        "a",
        { attrs: { href: "https://curricle.wpengine.com/", target: "_blank" } },
        [_vm._v("\n      news")]
      ),
      _vm._v("\n    on updates and new features.\n  ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }