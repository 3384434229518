var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("\n    Plan as List:\n  ")]),
      _vm._v(" "),
      _vm.filteredCourses
        ? _c("my-harvard-link", {
            staticClass: "mt-4 mb-2",
            attrs: { courses: _vm.filteredCourses }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("department-selector", { staticClass: "mb-4" }),
      _vm._v(" "),
      _c("semester-selector", {
        staticClass: "mb-4",
        attrs: { mode: "filter", source: "tray" }
      }),
      _vm._v(" "),
      _vm._l(_vm.filteredCourses, function(course) {
        return _c(
          "div",
          {
            key: course.id,
            staticClass: "row plan-list-item mb-3 mx-1 py-2",
            class: { selected: _vm.selected(course) },
            on: {
              click: function($event) {
                return _vm.selectCourse(course)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "col-md-1 pt-1 pointer" },
              [
                _vm.courseIdImported(course.id)
                  ? [
                      _c("course-action", {
                        staticClass: "mr-4",
                        attrs: {
                          "course-id": course.id,
                          invert: _vm.selected(course),
                          type: "imported"
                        }
                      })
                    ]
                  : [
                      _c("course-action", {
                        staticClass: "mr-1",
                        attrs: {
                          "course-id": course.id,
                          invert: _vm.selected(course),
                          type: "tray"
                        }
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("course-action", {
                        staticClass: "mr-3",
                        attrs: {
                          "course-id": course.id,
                          invert: _vm.selected(course),
                          type: "schedule"
                        }
                      })
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-2 pointer" },
              [
                _c(
                  "div",
                  {
                    staticClass: "text-uppercase font-weight-bold course-title"
                  },
                  [_vm._v("\n        " + _vm._s(course.title) + "\n      ")]
                ),
                _vm._v(" "),
                _vm._l(course.courseInstructors, function(instructor) {
                  return _c(
                    "span",
                    { key: instructor.id, staticClass: "course-instructor" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(instructor.displayName) +
                          "\n      "
                      )
                    ]
                  )
                })
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-2 pointer text-uppercase" }, [
              _vm._v(
                "\n      " +
                  _vm._s(course.subject) +
                  " " +
                  _vm._s(course.catalogNumber)
              ),
              _c("br"),
              _vm._v(
                "\n      " +
                  _vm._s(course.termName) +
                  " " +
                  _vm._s(course.termYear)
              ),
              _c("br"),
              _vm._v("\n      " + _vm._s(course.component) + "\n    ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-5 pointer" },
              [
                course.courseDescriptionLong
                  ? _c("truncate", {
                      staticClass: "course_description",
                      attrs: {
                        length: 200,
                        text: course.courseDescriptionLong,
                        clamp: "[..]",
                        less: "Show Less",
                        type: "html"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-2 px-0" },
              [
                _c("class-meeting-time", {
                  attrs: { course: course, condensed: true }
                })
              ],
              1
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }