var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "text-uppercase font-weight-bold" }, [
      _vm._v("\n    Learning Styles\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb-4", attrs: { id: "vis-description" } },
      [
        _c("p", [
          _vm._v(
            "\n      Explore the teaching and learning format of classes across the university.\n      Once you have selected the format that interests you, click on a specific\n      department to display the corresponding courses. Click again on the department\n      and format you have chosen to reset the visualization.\n    "
          )
        ]),
        _vm._v(" "),
        _c("semester-input"),
        _vm._v(" "),
        _c("course-level-input", {
          model: {
            value: _vm.courseLevel,
            callback: function($$v) {
              _vm.courseLevel = $$v
            },
            expression: "courseLevel"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "visContainer" } }, [
      _c("div", { attrs: { id: "courseTypeVis" } }, [
        _c(
          "p",
          {
            staticClass: "text-uppercase",
            attrs: { id: "courseTypeHeadline" }
          },
          [_vm._v("\n        Formats\n      ")]
        )
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "departmentVis" } }, [
        _c(
          "p",
          {
            staticClass: "text-uppercase",
            attrs: { id: "departmentHeadline" }
          },
          [_vm._v("\n        Departments\n      ")]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "w-100 float-left", attrs: { id: "classVis" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }