var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "mt-5" },
    [
      _c(
        "b-col",
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "font-weight-bold" }, [
                _vm._v("\n        " + _vm._s(_vm.semester) + "\n\n        "),
                _c("hr")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.courses, function(course) {
            return _c(
              "b-row",
              {
                key: course.index,
                staticClass: "mb-1 mx-1 pointer",
                class: {
                  selected:
                    _vm.currentCourse && _vm.currentCourse.id === course.id
                },
                on: {
                  click: function($event) {
                    return _vm.selectCourse(course)
                  }
                }
              },
              [
                _c(
                  "b-col",
                  {
                    staticClass: "text-uppercase font-weight-bold",
                    attrs: { cols: "6" }
                  },
                  [_vm._v("\n        " + _vm._s(course.title) + "\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  { staticClass: "text-muted", attrs: { cols: "2" } },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.firstInstructorName(course)) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  {
                    staticClass: "text-muted text-uppercase",
                    attrs: { cols: "2" }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(course.subject) +
                        " " +
                        _vm._s(course.catalogNumber) +
                        "\n      "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-col",
                  {
                    staticClass: "text-muted text-uppercase",
                    attrs: { cols: "2" }
                  },
                  [_vm._v("\n        " + _vm._s(course.component) + "\n      ")]
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }