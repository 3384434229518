var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.userAuthenticated
    ? _c("home-anonymous")
    : _c("home-authenticated")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }