var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    { attrs: { toggleable: "lg", type: "dark" } },
    [
      _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
      _vm._v(" "),
      _c("router-link", { attrs: { to: "/" } }, [
        _c("img", {
          staticClass: "w-50 pull-right",
          attrs: { src: "/images/logos/curricle_black_bg_no_beta.svg" }
        })
      ]),
      _vm._v(" "),
      _c(
        "b-collapse",
        { staticClass: "mt-3", attrs: { id: "nav-collapse", "is-nav": "" } },
        [
          _c(
            "b-navbar-nav",
            [
              _c(
                "b-nav-item",
                { staticClass: "mt-3", on: { click: _vm.trayToggle } },
                [_vm._v("\n        Schedule\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                { staticClass: "mt-3", attrs: { to: "/explore" } },
                [_vm._v("\n        Explore\n      ")]
              ),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/search" } }, [
                _vm._v("\n        Search\n      ")
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/plan" } }, [
                _vm._v("\n        Plan\n      ")
              ]),
              _vm._v(" "),
              _c(
                "b-nav-item",
                { staticClass: "mt-4", attrs: { to: "/tools" } },
                [_vm._v("\n        Tools\n      ")]
              ),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/faq" } }, [
                _vm._v("\n        FAQ\n      ")
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/about" } }, [
                _vm._v("\n        About\n      ")
              ]),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  attrs: {
                    target: "_blank",
                    href: "http://curricle.wpengine.com"
                  }
                },
                [_vm._v("\n        Development Updates\n      ")]
              ),
              _vm._v(" "),
              _c(
                "b-nav-item",
                {
                  staticClass: "mt-4",
                  attrs: { target: "_blank", href: "https://curricle.net" }
                },
                [_vm._v("\n        Curricle Lens\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }