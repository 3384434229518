var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row py-5" }, [
    _c("div", { staticClass: "col-md-8" }, [
      _c("h3", [_vm._v("\n      Frequently Asked Questions:\n    ")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("h5", [
        _vm._v("\n      How is Curricle different from my.harvard?\n    ")
      ]),
      _vm._v(" "),
      _c(
        "p",
        [
          _vm._v(
            "\n      Glad that you asked. While my.harvard is the centralized information\n      system used by Harvard students to manage course registration and by\n      faculty, staff, and administrators to oversee enrollments and advising,\n      Curricle is designed with the curriculum firmly in mind. It is built to\n      prompt intellectual curiosity and adventure, and to help with planning.\n      To that end, it offers\n      "
          ),
          _c("router-link", { attrs: { to: "/explore" } }, [
            _vm._v("\n        a set of engaging visualizations")
          ]),
          _vm._v(
            "\n      to help you navigate\n      the Harvard course catalog and discover interesting classes. It also provides\n      a planning tool that gives you\n      "
          ),
          _c("router-link", { attrs: { to: "/plan/week" } }, [
            _vm._v("\n        a weekly calendar view")
          ]),
          _vm._v("\n      of your schedule,\n      "),
          _c("router-link", { attrs: { to: "/plan/semester" } }, [
            _vm._v("\n        semester-by-semester planning")
          ]),
          _vm._v(
            "\n      with an automatically imported transcript,\n      and annotations to help you stay organized. Curricle further streamlines\n      the course selection process by connecting courses directly to their syllabi\n      and to Q evaluations while letting you export your courses to my.harvard with a single click.\n    "
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("h5", [
        _vm._v(
          "\n      Are the courses I'm seeing on Curricle also on my.harvard?\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      While Curricle course data are updated frequently, my.harvard\n      is the home of Harvard's authoritative course database. Be sure\n      to check there to get changes or additions to the latest course listings.\n    "
        )
      ]),
      _vm._v(" "),
      _c("h5", [
        _vm._v(
          '\n      How should I make use of my "Schedule" in Curricle?\n    '
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          '\n      Creatively! Your "Schedule" is where you can add courses that\n      potentially interest you. You can sift through these courses,\n      view them in a weekly plan, annotate them, and share them with\n      friends and advisers. Your schedule is yours alone. Unless you\n      share it with others, it\'s your place to collect, curate, and\n      play with course plans and ideas.\n    '
        )
      ]),
      _vm._v(" "),
      _c("h5", [
        _vm._v(
          "\n      Once I have added some courses to my schedule, am I done?\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Not quite. Make sure you click on the Export to my.harvard button\n      to send your schedule over to my.harvard where you can complete\n      the enrollment process. Curricle is a course discovery, exploration,\n      and planning tool, not a registration platform.\n    "
        )
      ]),
      _vm._v(" "),
      _c("h5", [
        _vm._v("\n      What gets shared when I click on Share Schedule?\n    ")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      When you click on Share Schedule, Curricle generates a link to\n      the Week View of your schedule with all the classes you have\n      added, even if they are hidden.\n    "
        )
      ]),
      _vm._v(" "),
      _c("h5", [
        _vm._v("\n      Are the annotations I add to courses private?\n    ")
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Your personal notes for courses — whether they are added\n      to your schedule or not — will remain private and visible\n      only to you.\n    "
        )
      ]),
      _vm._v(" "),
      _c("h5", [
        _vm._v(
          "\n      A visualization doesn’t seem to work properly. Is it broken?\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Try refreshing the page if one of Curricle’s EXPLORE\n      visualizations is behaving in an unexpected manner. If you\n      suspect it’s a bug, please let us know. Our goal is to keep\n      improving the tool.\n    "
        )
      ]),
      _vm._v(" "),
      _c("h5", [
        _vm._v(
          "\n      Can the advanced search filter classes by times of the day or days of the week?\n    "
        )
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n      Search by times when classes meet is a feature that is currently\n      in the works. We will update the platform as soon as we have that\n      feature tested, so check back soon!\n    "
        )
      ]),
      _vm._v(" "),
      _c("h5", [_vm._v("\n      What is Curricle Lens?\n    ")]),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("h5", [
        _vm._v(
          "\n      This tool is pretty cool, but I do have some thoughts/feedback about it. How can I contribute?\n    "
        )
      ]),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-5 vertical-align" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-4" },
          [
            _c(
              "b-button",
              {
                staticClass: "w-100",
                attrs: {
                  href: "mailto:info@metalab.harvard.edu",
                  variant: "dark"
                }
              },
              [_vm._v("\n          Contact Us\n        ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12 col-md-8" }, [
          _vm._v(
            "\n        Is there a bug you’d like to report? Want to provide some feedback? Write us and we’ll get back to you as soon as we can.\n      "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n      Get all of your burning questions about Curricle answered here. If your question isn’t addressed,\n      feel free reach out to us at "
      ),
      _c("a", { attrs: { href: "mailto:info@metalab.harvard.edu" } }, [
        _vm._v("info@metalab.harvard.edu")
      ]),
      _vm._v(".\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n      Curricle Lens is a research platform where you can dig into\n      data-driven stories about the history of the Harvard curriculum.\n      Leveraging the strength of visualized data and interactive\n      storytelling, Curricle Lens can take you on a guided tour of how\n      the Department of African and African-American Studies has grown\n      and transformed, how the curricular tension between humanities and\n      sciences shifted over time, how prominent luminaries like Gertrude\n      Stein and T. S. Eliot navigated the course catalog in their\n      collegiate days, and many more stories like these.\n      Check it out "
      ),
      _c("a", { attrs: { href: "https://curricle.net/" } }, [_vm._v("here")]),
      _vm._v("!\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n      We welcome suggestions and collaborators — whether you're\n      a student, an instructor, or an administrator. Please get in touch\n      with us at "
      ),
      _c("a", { attrs: { href: "mailto:info@metalab.harvard.edu" } }, [
        _vm._v("info@metalab.harvard.edu")
      ]),
      _vm._v("\n      or click on the Contact Us button below.\n    ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }