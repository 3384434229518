var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "text-uppercase font-weight-bold" }, [
        _vm._v("\n    " + _vm._s(_vm.semester) + "\n  ")
      ]),
      _vm._v(" "),
      _vm._l(_vm.sortedCourses, function(course) {
        return _c(
          "div",
          {
            key: course.id,
            staticClass: "d-table mb-3 pointer",
            on: {
              click: function($event) {
                return _vm.selectCourse(course)
              }
            }
          },
          [
            _c("div", { staticClass: "d-table-row" }, [
              _c(
                "div",
                { staticClass: "d-table-cell pr-2" },
                [
                  _c("course-action", {
                    attrs: {
                      "course-id": course.id,
                      invert: true,
                      type: "tray"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-table-cell" },
                [
                  _c("truncate", {
                    staticClass: "text-uppercase",
                    class: _vm.courseIdStyles(course.id),
                    attrs: {
                      length: 30,
                      text: course.title,
                      clamp: "...",
                      less: " "
                    }
                  }),
                  _vm._v(" "),
                  _c("class-meeting-time", {
                    staticClass:
                      "meeting-time text-uppercase font-weight-normal",
                    class: _vm.courseIdStyles(course.id),
                    attrs: {
                      condensed: true,
                      course: course,
                      "tbd-icon": false
                    }
                  })
                ],
                1
              )
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }