var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "basic-search mb-3" },
        [
          _c("semester-input", { attrs: { "allow-range": true } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-4 w-50" },
            [
              _c(
                "b-input-group",
                [
                  _c("b-input-group-prepend", { attrs: { "is-text": "" } }, [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: "/images/icons/eye_black.png" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.keyword,
                        expression: "keyword"
                      }
                    ],
                    staticClass: "search pl-0 form-control",
                    attrs: { type: "search", placeholder: "Enter a keyword" },
                    domProps: { value: _vm.keyword },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.addActiveKeyword($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.keyword = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "b-input-group-append",
                    { attrs: { "is-text": "" } },
                    [
                      _c("basic-search-field-dropdown", {
                        attrs: { "apply-to": _vm.applyTo },
                        on: {
                          applyFilterChange: function($event) {
                            _vm.applyTo = $event
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-input-group-append", { attrs: { "is-text": "" } }, [
                    _c("img", {
                      staticClass: "icon pointer",
                      attrs: { src: "/images/icons/return_arrow.png" },
                      on: { click: _vm.addActiveKeyword }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("advanced-search"),
      _vm._v(" "),
      _c("basic-search-active-keywords"),
      _vm._v(" "),
      _c("basic-search-inactive-keywords")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }