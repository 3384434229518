var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "basic-search mb-3" },
      [
        _c("semester-input", { attrs: { "allow-range": true } }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mt-4 w-50" },
          [
            _c(
              "b-input-group",
              [
                _c("b-input-group-prepend", { attrs: { "is-text": "" } }, [
                  _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/images/icons/eye_black.png" }
                  })
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchQuery,
                      expression: "searchQuery"
                    }
                  ],
                  staticClass: "search pl-0 form-control",
                  attrs: { type: "search", placeholder: "Search courses" },
                  domProps: { value: _vm.searchQuery },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.performSearch($event)
                    },
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchQuery = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c("b-input-group-append", { attrs: { "is-text": "" } }, [
                  _c("img", {
                    staticClass: "icon pointer",
                    attrs: { src: "/images/icons/return_arrow.png" },
                    on: { click: _vm.performSearch }
                  })
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c(
                  "b-btn",
                  {
                    attrs: { id: "search-tips", variant: "outline-secondary" }
                  },
                  [_vm._v("\n          Search Tips\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      placement: "bottom",
                      target: "search-tips",
                      triggers: "click"
                    }
                  },
                  [
                    _vm._v(
                      "\n          Use * as a wildcard to search on partial words"
                    ),
                    _c("br"),
                    _vm._v("\n          Group search terms using ( )"),
                    _c("br"),
                    _vm._v('\n          Use | for "or" logic'),
                    _c("br"),
                    _vm._v(
                      "\n          Use - to exclude a specific term\n        "
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }