var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "pointer",
      attrs: { id: "sort-by-dropdown", tabindex: "0" }
    },
    [
      _vm._v("\n  sort by:\n  "),
      _c("strong", [_vm._v(_vm._s(_vm.sortByLabel))]),
      _vm._v(" "),
      _c("font-awesome-icon", { attrs: { icon: "caret-down" } }),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          ref: "popover",
          attrs: {
            placement: "bottom",
            target: "sort-by-dropdown",
            triggers: "click blur"
          }
        },
        [
          _c("b-form-radio-group", {
            attrs: {
              options: _vm.sortByOptions,
              buttons: "",
              "button-variant": "outline-dark",
              stacked: "",
              name: "sort-fields"
            },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }