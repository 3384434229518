var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-5" },
    [
      _c(
        "h3",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.exploreRoot,
              expression: "exploreRoot"
            }
          ]
        },
        [_vm._v("\n    Explore:\n  ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.exploreRoot,
              expression: "exploreRoot"
            }
          ],
          staticClass: "row mb-5"
        },
        [
          _c("div", { staticClass: "col-sm-12 col-md-5" }, [
            _vm._v(
              "\n      Use these visualizations to explore the Harvard course catalog,\n      follow unexpected pathways, and discover new courses.\n    "
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("router-view", { staticClass: "mt-5" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }