var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("\n    Plan by Semester:\n  ")]),
      _vm._v(" "),
      _vm.scheduledCourses
        ? _c("my-harvard-link", {
            staticClass: "my-4",
            attrs: { courses: _vm.scheduledCourses }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.sortedSemestersInSchedule, function(semester) {
        return _c("semester-credit-hours", {
          key: semester,
          attrs: {
            semester: semester,
            courses: _vm.scheduledCoursesBySemester[semester]
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }