var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "filter-container" } }, [
    _c("p", { staticClass: "heading" }, [
      _vm._v("\n    Filter results by:\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.facets, function(facet) {
        return _c(
          "div",
          { key: facet.key, staticClass: "col-md-3" },
          [_c("advanced-search-filters-facet", { attrs: { facet: facet } })],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }