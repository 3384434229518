var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-4" }, [
    _c("p", { staticClass: "heading" }, [_vm._v("\n    Annotations\n  ")]),
    _vm._v(" "),
    _c("div", [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.editableText,
            expression: "editableText"
          }
        ],
        staticClass: "p-1 w-100",
        attrs: { maxlength: _vm.maxLength, rows: "5" },
        domProps: { value: _vm.editableText },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.editableText = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "word-count" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.editableTextLength) +
            " / " +
            _vm._s(_vm.maxLength) +
            " characters\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "button-container" }, [
        _c(
          "button",
          { staticClass: "btn clearfix", on: { click: _vm.updateAnnotations } },
          [_vm._v("\n        Save\n      ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }