var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("visualization-card", {
        attrs: {
          title: "Keyword Comparisons",
          text:
            "Pick two search terms and check out the courses that fall into the area of overlap.",
          link: "/explore/keyword-comparisons",
          image: "/images/explore/keywords.png"
        }
      }),
      _vm._v(" "),
      _c("visualization-card", {
        attrs: {
          title: "Learning Styles",
          text:
            "Browse courses by their format (lab, lecture, seminar, tutorial, and so on).",
          link: "/explore/learning-styles",
          image: "/images/explore/departments.png"
        }
      }),
      _vm._v(" "),
      _c("visualization-card", {
        attrs: {
          title: "Instructor Networks",
          text:
            "Explore connections among faculty members by their courses and departments.",
          link: "/explore/instructor-networks",
          image: "/images/explore/instructors.png"
        }
      }),
      _vm._v(" "),
      _c("visualization-card", {
        attrs: {
          title: "Course Universe",
          text:
            "Zoom out and survey the entire course catalog; zoom in and look at individual departments and courses.",
          link: "/explore/course-universe",
          image: "/images/explore/universe.png"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }