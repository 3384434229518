var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("p", { staticClass: "text-uppercase font-weight-bold" }, [
      _vm._v("\n    Keyword Comparisons\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "mb-4", attrs: { id: "vis-description" } },
      [
        _c("p", [
          _vm._v(
            "\n      Enter two keywords at the left and right to visualize the courses in which\n      these two words intersect from the standpoint of class formats, academic\n      disciplines, or specific class offerings. To reset the visualization,\n      simply enter new keywords.\n    "
          )
        ]),
        _vm._v(" "),
        _c("semester-input"),
        _vm._v(" "),
        _c("course-level-input", {
          model: {
            value: _vm.courseLevel,
            callback: function($$v) {
              _vm.courseLevel = $$v
            },
            expression: "courseLevel"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("h4", { staticClass: "text-center text-uppercase font-weight-bold" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.keywordOne) +
          " / " +
          _vm._s(_vm.keywordTwo) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c("div", { attrs: { id: "interfaceContainer" } }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { attrs: { id: "searchContainer" } }, [
        _c("div", { staticClass: "searchBox", attrs: { id: "searchOne" } }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchBoxOne,
                expression: "searchBoxOne"
              }
            ],
            staticClass: "searchBoxInput",
            attrs: {
              id: "searchBoxOne",
              type: "text",
              placeholder: "search term..."
            },
            domProps: { value: _vm.searchBoxOne },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.updateKeywords($event)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchBoxOne = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "searchBoxButton",
              attrs: { id: "searchBoxOneButton" }
            },
            [_vm._v("\n          >\n        ")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "searchBox", attrs: { id: "searchTwo" } }, [
          _c(
            "button",
            {
              staticClass: "searchBoxButton",
              attrs: { id: "searchBoxTwoButton" }
            },
            [_vm._v("\n          <\n        ")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchBoxTwo,
                expression: "searchBoxTwo"
              }
            ],
            staticClass: "searchBoxInput",
            attrs: {
              id: "searchBoxTwo",
              type: "text",
              placeholder: "search term..."
            },
            domProps: { value: _vm.searchBoxTwo },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.updateKeywords($event)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchBoxTwo = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "visContainer" } })
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showNoResultsContainer,
            expression: "showNoResultsContainer"
          }
        ],
        staticClass: "text-center",
        attrs: { id: "no-results" }
      },
      [
        _c("strong", [
          _vm._v("\n      No results found for that search.\n    ")
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "dimContainer" } }, [
      _c("ul", [
        _c("li", { staticClass: "dimSelect", attrs: { value: "component" } }, [
          _vm._v("\n          Learning Styles\n        ")
        ]),
        _vm._v(" |\n\n        "),
        _c(
          "li",
          {
            staticClass: "dimSelect active",
            attrs: { value: "subjectDescription" }
          },
          [_vm._v("\n          Subjects\n        ")]
        ),
        _vm._v(" |\n\n        "),
        _c(
          "li",
          {
            staticClass: "dimSelect",
            attrs: { id: "dimClass", value: "title" }
          },
          [_vm._v("\n          Classes\n        ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }