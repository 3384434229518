var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dropdown",
    { attrs: { text: _vm.semesterLabel } },
    [
      _c(
        "b-dropdown-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mode !== "state",
              expression: "mode !== 'state'"
            }
          ],
          on: {
            click: function($event) {
              return _vm.setSemester(null)
            }
          }
        },
        [_vm._v("\n    All Semesters\n  ")]
      ),
      _vm._v(" "),
      _vm._l(_vm.sortedSemestersInTray, function(sem) {
        return _c(
          "b-dropdown-item",
          {
            key: sem,
            on: {
              click: function($event) {
                return _vm.setSemester(sem)
              }
            }
          },
          [_vm._v("\n    " + _vm._s(sem) + "\n  ")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }