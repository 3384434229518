var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("\n    Plan by Week:\n  ")]),
      _vm._v(" "),
      _vm.scheduleCourses.length
        ? _c("my-harvard-link", {
            staticClass: "mt-4 mb-2",
            attrs: { courses: _vm.scheduleCourses }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("share-schedule-link", {
        staticClass: "mt-4 mb-2",
        attrs: {
          id: "share-schedule-link-plan-week",
          size: "sm",
          variant: "dark"
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("semester-selector", {
        staticClass: "mb-4",
        attrs: { mode: "state", source: "schedule" }
      }),
      _vm._v(" "),
      _c(
        "b-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.coursesTBD && _vm.coursesTBD.length,
              expression: "coursesTBD && coursesTBD.length"
            }
          ]
        },
        [
          _c(
            "b-col",
            { staticStyle: { height: "150px" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "md-1 font-weight-bold text-uppercase" },
                    [_vm._v("\n          Schedule TBD:\n        ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                _vm._l(_vm.coursesTBD, function(course) {
                  return _c(
                    "b-col",
                    { key: course.id },
                    [
                      _c("calendar-item", {
                        attrs: {
                          item: course,
                          scale: _vm.scale,
                          offset: 0,
                          height: 1,
                          nudge: -65,
                          provisional: _vm.provisionalCourseIds.includes(
                            course.id
                          ),
                          selected:
                            _vm.currentCourse &&
                            _vm.currentCourse.id == course.id,
                          hidden: !_vm.courseIdInSchedule(course.id),
                          "read-only": _vm.readOnly
                        }
                      })
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasCourses,
              expression: "hasCourses"
            }
          ]
        },
        [
          _c(
            "b-col",
            [
              _c(
                "b-row",
                { staticClass: "header-row" },
                [
                  _c("b-col", { staticClass: "header" }, [
                    _vm._v("\n           \n        ")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    _vm._l(_vm.times, function(time, index) {
                      return _c(
                        "span",
                        {
                          key: time,
                          staticClass: "time-label",
                          style: {
                            display: "block",
                            position: "absolute",
                            top: index * _vm.scale + "px",
                            width: "575%",
                            "border-top": "1px solid lightgray"
                          }
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(time) + "\n          "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.daylist, function(day, index) {
            return _c(
              "b-col",
              { key: index, staticClass: "day-column" },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { staticClass: "header" }, [
                      _vm._v("\n          " + _vm._s(day) + "\n        ")
                    ])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-row",
                  _vm._l(_vm.currentTrayByDay[index], function(item) {
                    return _c("calendar-item", {
                      key: item.course.id,
                      attrs: {
                        item: item.course,
                        scale: _vm.scale,
                        offset: item.meetingTime[0] - _vm.earliestIdx,
                        height: item.meetingTime[1],
                        provisional: _vm.provisionalCourseIds.includes(
                          item.course.id
                        ),
                        selected:
                          _vm.currentCourse &&
                          _vm.currentCourse.id == item.course.id,
                        conflicted:
                          item.course.id in _vm.scheduledCourseConflictsByDay,
                        "conflict-info": _vm.courseConflictInfoForDay(
                          item.course.id,
                          day
                        ),
                        hidden: !_vm.courseIdInSchedule(item.course.id),
                        "read-only": _vm.readOnly
                      }
                    })
                  }),
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }