var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-form", { attrs: { inline: "" } }, [
    _c(
      "label",
      [
        _vm._v("\n    Course Level:\n\n    "),
        _c("b-form-select", {
          staticClass: "ml-2",
          attrs: { options: _vm.options, value: _vm.value },
          on: { change: _vm.changeHandler }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }