var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasData && !_vm.pastSemester
    ? _c(
        "div",
        _vm._l(_vm.week, function(day, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.condensed || day.timeBar,
                  expression: "!condensed || day.timeBar"
                }
              ],
              key: index,
              class: { conflicted: _vm.conflicts[index] }
            },
            [
              _c(
                "div",
                { class: { inactive: !(day.timeBar && day.timeBar.length) } },
                [
                  _c("span", { staticClass: "day-name" }, [
                    _vm._v("\n        " + _vm._s(day.abbrev) + "\n      ")
                  ]),
                  _vm._v("\n\n      " + _vm._s(day.timePretty) + "\n    ")
                ]
              )
            ]
          )
        }),
        0
      )
    : _c("div", [
        _vm.pastSemester
          ? _c("div", [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.course.termName) +
                  " " +
                  _vm._s(_vm.course.termYear) +
                  "\n  "
              )
            ])
          : _c("div", [
              _vm.tbdIcon
                ? _c("img", {
                    staticClass: "icon-tbd",
                    attrs: { src: "/images/icons/tbd.png", alt: "Schedule TBD" }
                  })
                : _c("p", [_vm._v("\n      TBD\n    ")])
            ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }