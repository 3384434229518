var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mx-0" }, [
    _c("div", { staticClass: "col-md-12 top-header" }, [
      _c(
        "div",
        [
          _c("p", [_vm._v("Shared Schedule")]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("week-view", { attrs: { "read-only": true } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }