var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.activeSemestersInTray, function(semester) {
      return _c("tray-list-semester", {
        key: semester,
        attrs: {
          semester: semester,
          courses: _vm.trayCoursesBySemester[semester]
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }