var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overflow-hidden", attrs: { id: "app" } },
    [
      _c("loader-overlay"),
      _vm._v(" "),
      _c("the-mobile-menu", { staticClass: "d-block d-sm-none" }),
      _vm._v(" "),
      _vm.userAuthenticated && _vm.trayVisible
        ? _c("the-navbar-control", { staticClass: "d-none d-sm-block" })
        : _vm._e(),
      _vm._v(" "),
      _vm.userAuthenticated && !_vm.trayVisible
        ? _c("the-tray-control", { staticClass: "d-none d-sm-block" })
        : _vm._e(),
      _vm._v(" "),
      !_vm.trayVisible
        ? _c("the-authentication-link", {
            staticClass: "d-none d-sm-block",
            attrs: { "user-authenticated": _vm.userAuthenticated }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        [
          !_vm.trayVisible
            ? _c(
                "div",
                {
                  staticClass: "col-sm-2 d-none d-sm-block pr-0",
                  class: { active: !_vm.trayVisible },
                  attrs: { id: "nav-container" }
                },
                [
                  _c("navbar", {
                    attrs: { "user-authenticated": _vm.userAuthenticated }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "col-sm-10 d-sm-block",
              class: { "d-none": _vm.trayVisible },
              attrs: { id: "main-container" }
            },
            [
              _c("the-alert"),
              _vm._v(" "),
              _c(
                "keep-alive",
                [
                  _c("router-view", {
                    attrs: { "user-authenticated": _vm.userAuthenticated }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.selectedCourse
                ? _c("selected-course", {
                    attrs: {
                      "course-id": _vm.selectedCourse.id,
                      "user-authenticated": _vm.userAuthenticated
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.trayVisible
            ? _c(
                "div",
                {
                  staticClass: "col-sm-2 pl-0",
                  class: { active: _vm.trayVisible, "col-12": _vm.trayVisible },
                  attrs: { id: "tray-container" }
                },
                [_c("the-tray")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("the-footer", { staticClass: "d-none d-sm-flex" })
        ],
        1
      ),
      _vm._v(" "),
      _c("course-observer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }