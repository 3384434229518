var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "py-5" },
    [
      _c("h3", [_vm._v("Advanced Search:")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    Narrow down your search to specific parts of course catalog entries and filter your results by additional parameters.\n  "
        )
      ]),
      _vm._v(" "),
      _vm.userAuthenticated ? _c("search-form") : _vm._e(),
      _vm._v(" "),
      _c("search-results", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.searchComplete,
            expression: "searchComplete"
          }
        ],
        attrs: { "search-type": "advanced" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }