var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "row fixed-bottom px-3" }, [
    _c(
      "div",
      { staticClass: "col-md-6 d-flex align-self-center" },
      [_c("the-data-last-updated-indicator")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "col-md-6 d-flex align-self-center justify-content-md-end"
      },
      [_c("the-feedback-link")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }