var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "d-none d-md-block pt-5 px-4", attrs: { id: "nav-main" } },
    [
      _c(
        "div",
        { staticClass: "text-center mb-4", attrs: { id: "logo" } },
        [
          _c("router-link", { attrs: { to: "/" } }, [
            _c("img", {
              staticClass: "w-100",
              attrs: { src: "/images/logos/curricle_black_bg_no_beta.svg" }
            })
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "nav primary",
          class: _vm.classObject,
          attrs: { id: "explore-link", to: "/explore" }
        },
        [_vm._v("\n    Explore\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            boundary: "viewport",
            placement: "right",
            target: "explore-link",
            triggers: "hover"
          }
        },
        [
          _vm._v("\n    Expand your horizons by exploring\n    "),
          _c("br"),
          _vm._v("\n    visualizations of the course catalog\n  ")
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeNavPrimary === "explore",
              expression: "activeNavPrimary === 'explore'"
            }
          ],
          staticClass: "nav-sub-container"
        },
        [
          _c(
            "router-link",
            {
              staticClass: "nav sub",
              attrs: { to: "/explore/keyword-comparisons" }
            },
            [_vm._v("\n      Keyword Comparisons\n    ")]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "nav sub",
              attrs: { to: "/explore/learning-styles" }
            },
            [_vm._v("\n      Learning Styles\n    ")]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "nav sub",
              attrs: { to: "/explore/instructor-networks" }
            },
            [_vm._v("\n      Instructor Networks\n    ")]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "router-link",
            {
              staticClass: "nav sub",
              attrs: { to: "/explore/course-universe" }
            },
            [_vm._v("\n      Course Universe\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "nav primary",
          class: _vm.classObject,
          attrs: { id: "search-link", to: "/search" }
        },
        [_vm._v("\n    Search\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            boundary: "viewport",
            placement: "right",
            target: "search-link",
            triggers: "hover"
          }
        },
        [
          _vm._v("\n    Narrow down your choices by applying\n    "),
          _c("br"),
          _vm._v("\n    custom filters and parameters\n  ")
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeNavPrimary === "search",
              expression: "activeNavPrimary === 'search'"
            }
          ],
          staticClass: "nav-sub-container"
        },
        [
          _c(
            "router-link",
            { staticClass: "nav sub", attrs: { to: "/search/advanced" } },
            [_vm._v("\n      Advanced Search\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "nav primary",
          class: _vm.classObject,
          attrs: { id: "plan-link", to: "/plan" }
        },
        [_vm._v("\n    Plan\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            boundary: "viewport",
            placement: "right",
            target: "plan-link",
            triggers: "hover"
          }
        },
        [
          _vm._v("\n    Plan out your schedule, whether\n    "),
          _c("br"),
          _vm._v("\n    for this semester or the long haul\n  ")
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.activeNavPrimary === "plan",
              expression: "activeNavPrimary === 'plan'"
            }
          ],
          staticClass: "nav-sub-container"
        },
        [
          _c(
            "router-link",
            { staticClass: "nav sub", attrs: { to: "/plan/week" } },
            [_vm._v("\n      Week\n    ")]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "nav sub", attrs: { to: "/plan/semester" } },
            [_vm._v("\n      Semester\n    ")]
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "nav sub", attrs: { to: "/plan/list" } },
            [_vm._v("\n      List\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "nav secondary",
          class: _vm.classObject,
          attrs: { id: "tools-link", to: "/tools" }
        },
        [_vm._v("\n    Tools\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            boundary: "viewport",
            placement: "right",
            target: "tools-link",
            triggers: "hover"
          }
        },
        [
          _vm._v("\n    Your one-stop shop for links important\n    "),
          _c("br"),
          _vm._v("\n    for the course selection process\n  ")
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "nav secondary", attrs: { id: "faq-link", to: "/faq" } },
        [_vm._v("\n    FAQ\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            boundary: "viewport",
            placement: "right",
            target: "faq-link",
            triggers: "hover"
          }
        },
        [
          _vm._v("\n    Find answers to common\n    "),
          _c("br"),
          _vm._v("\n    questions — or ask one yourself\n  ")
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "nav secondary",
          attrs: { id: "about-link", to: "/about" }
        },
        [_vm._v("\n    About\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            boundary: "viewport",
            placement: "right",
            target: "about-link",
            triggers: "hover"
          }
        },
        [
          _vm._v("\n    Read about Curricle and the\n    "),
          _c("br"),
          _vm._v("\n    Curricle development team\n  ")
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "nav secondary",
          attrs: {
            id: "news-link",
            target: "_blank",
            href: "http://curricle.wpengine.com/"
          }
        },
        [_vm._v("\n    News\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            boundary: "viewport",
            placement: "right",
            target: "news-link",
            triggers: "hover"
          }
        },
        [
          _vm._v("\n    Keep up to date on the latest\n    "),
          _c("br"),
          _vm._v("\n    features and project updates\n  ")
        ]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "nav secondary",
          attrs: {
            id: "curricle-lens-link",
            target: "_blank",
            href: "https://curricle.net"
          }
        },
        [_vm._v("\n    Curricle Lens\n  ")]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            boundary: "viewport",
            placement: "right",
            target: "curricle-lens-link",
            triggers: "hover"
          }
        },
        [
          _vm._v("\n    Dig into data-driven stories about the\n    "),
          _c("br"),
          _vm._v("\n    history of the Harvard curriculum\n  ")
        ]
      ),
      _vm._v(" "),
      _c("hr")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }